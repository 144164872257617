/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-2",
    "aws_cloud_logic_custom": [
        {
            "name": "LighthouseWebApi",
            "endpoint": "https://6yw9a5rg2g.execute-api.ap-northeast-2.amazonaws.com/prod",
            "region": "ap-northeast-2"
        }
    ],
    "aws_dynamodb_all_tables_region": "ap-northeast-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "LIGHTHOUSECH",
            "region": "ap-northeast-2"
        }
    ]
};


export default awsmobile;
