import React, { Component } from 'react'

import SermonsItem from 'components/SermonsItem';

class SermonsList extends Component {
  getQuery = () => {
    const query = this.props.query;
    const lecture = query.lecture !== '' ? query.lecture + ' 강해' : '';
    return query.search || query.series || lecture;
  }

  render() {
    const listSermons = this.props.sermons.map((sermon, index) => {
      return (
        <li key={index} className='clearfix'><SermonsItem sermon={sermon} /></li>
      );
    });
    const query = this.getQuery();

    return (
      <div className='list-container'>
        {query !== '' &&
          <div className='query-container clearfix'>
            <div className='search-query'>'{query}'에 대한 검색 결과.</div>
            <div className='clear-query' onClick={this.props.handlers.clearQuery}>전체 목록으로 돌아가기</div>
          </div>
        }
        <ul>{listSermons}</ul>
      </div>
    )
  }
}

export default SermonsList