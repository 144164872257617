import React, { Component } from 'react'
import { Button, Dropdown, Input } from 'semantic-ui-react'

const SERMON_TYPES = [
  '전체',
  '주일예배',
  '목요예배',
  '새벽기도회'
];

class SermonsHeader extends Component {
  state = {
    query: ''
  }

  updateQuery = (evt, data) => {
    this.setState({
      query: data.value
    });
  }

  handleSearch = () => {
    this.props.handlers.updateSearch(this.state.query);
  }

  handleKeypress = (evt) => {
    if (evt.key === 'Enter') {
      this.handleSearch();
    }
  }

  render() {
    const listSermons = SERMON_TYPES.map((type, index) => {
      return (
        <Button key={index} 
                active={type === this.props.type}
                onClick={() => this.props.handlers.updateType(type)}>{type}</Button>
      );
    });

    return (
      <div className='header clearfix'>
        <h4 className='left'>설교</h4>
        <Button.Group>{listSermons}</Button.Group>
        <Dropdown inline text='주제설교' options={this.props.options.series} onChange={this.props.handlers.updateSeries} />
        <Dropdown inline text='강해설교' options={this.props.options.lecture} onChange={this.props.handlers.updateLecture} />
        <Input className='right'
               onChange={this.updateQuery}
               action={{content: '검색', onClick: this.handleSearch}}
               onKeyPress={this.handleKeypress} />
      </div>
    )
  }
}

export default SermonsHeader