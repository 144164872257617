import React, { Component } from 'react'

import { Icon, Loader } from 'semantic-ui-react';
import * as axios from 'axios';

const BUCKET_URL = 'https://lighthousech-sermons.s3.ap-northeast-2.amazonaws.com/';

class SermonsItem extends Component {
  state = {
    downloading: false,
  }

  playSermon = () => {
    const sermon = this.props.sermon;
    if (!sermon.filename && !sermon.youtube) return;

    const link = sermon.youtube ? sermon.youtube.S : BUCKET_URL + sermon.filename.S;
    window.open(link, "_blank");
  }

  downloadSermon = () => {
    const filename = this.props.sermon.filename.S;
    axios({
      method: 'get',
      url: BUCKET_URL + filename,
      responseType: 'blob'
    }).then((response) => {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.remove();

      this.setState({downloading: false});
    });

    this.setState({downloading: true});
  }

  render() {
    const sermon = this.props.sermon;

    return (
      <div className='item'>
        <div className='item-stream' onClick={() => this.playSermon(sermon.path)}>
          <div className='first-column flex-center'>
            <h5>{sermon.order}.</h5>
            <Icon name='play circle' size='big' />
          </div>
          <div className='second-column'>
            <h5 className='title'>{sermon.reference !== undefined && sermon.title.S}</h5>
            <h5 className='reference'>{sermon.reference !== undefined && sermon.reference.S}</h5>
            <h5 className='pastor'>{sermon.pastor !== undefined && sermon.pastor.S}</h5>
            <h5 className='type'>{sermon.sermon_type !== undefined && sermon.sermon_type.S}</h5>
            <h5 className='date'>{sermon.date !== undefined && sermon.date.S}</h5>
          </div>
        </div>
        { sermon.youtube === undefined &&
        <div className='item-download flex-center'>
          <svg xmlns="http://www.w3.org/2000/svg"
               alt='다운로드' className={this.state.downloading ? 'hidden' : ''} onClick={this.downloadSermon}>
            <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"/>
            <path d="M0 0h24v24H0z" fill="none"/>
          </svg>
          <Loader active={this.state.downloading} inline />
        </div>
        }
      </div>
    )
  }
}

export default SermonsItem