import React, { Component } from 'react'

class Schedule extends Component {
  render() {
    return (
      <section id='schedule' className='flex-vcenter'>
        <div className='contents'>
          <h4>예배안내</h4>
          <div className='column'>
            <h5>주일예배: 오전 11:00</h5>
            <h5>주일학교 (초등부, 중등부): 오전 11:00</h5>
          </div>
          <div className='column'>
            <h5>금요기도회: 오후 8:00 Zoom</h5>
            <h5>매일기도회: 오후 8:00 Zoom</h5>
          </div>
        </div>
      </section>
    )
  }
}

export default Schedule