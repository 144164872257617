import React, { Component } from 'react'

import * as Scroll from 'react-scroll';

import logo from 'assets/icons/logo.svg';

const scroller = Scroll.animateScroll;

class Navbar extends Component {
  scrollTo = (top) => {
    scroller.scrollTo(top, {duration: 500});
  }

  render() {
    return (
      <section id='navbar'>
        <div id='navbar-container'>
          <div><img src={logo} alt='진리등대교회' /></div>
          <ul>
            <li id='nav-schedule' onClick={() => this.scrollTo(430)}>예배안내</li>
            <li id='nav-pastors' onClick={() => this.scrollTo(780)}>섬기는 분들</li>
            <li id='nav-direction' onClick={() => this.scrollTo(1180)}>오시는길</li>
            <li id='nav-announcements' onClick={() => this.scrollTo(1580)}>교회소식</li>
            <li id='nav-sermons' onClick={() => this.scrollTo(1980)}>설교</li>
          </ul>
        </div>
      </section>
    )
  }
}

export default Navbar