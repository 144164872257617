import React, { Component } from 'react'

class Intro extends Component {
  render() {
    return (
      <section id='intro' className='flex-vcenter'>
        <div className='contents'><h2>꺼지지 않는 하나님의 등불이 되어 어둠에 빛을 밝히는 교회</h2></div>
      </section>
    )
  }
}

export default Intro