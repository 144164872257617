import React, { Component } from 'react'

class Pastors extends Component {
  render() {
    return (
      <section id='pastors' className='flex-vcenter'>
        <div className='contents'><div className='wrapper'>
          <h4>섬기는 분들</h4>
          <h5><span>담임목사:</span> 김수연 목사</h5>
          <h5><span>부교역자:</span> 신동수 목사</h5>
        </div></div>
      </section>
    )
  }
}

export default Pastors