import React, { Component } from 'react'

import Announcements from 'components/Announcements';
import Direction from 'components/Direction';
import Intro from 'components/Intro';
import Navbar from 'components/Navbar';
import Schedule from 'components/Schedule';
import Sermons from 'components/Sermons';
import Pastors from 'components/Pastors';

class MainScene extends Component {
  render() {
    return ([
      <Navbar key='navbar' />,
      <Intro key='intro' />,
      <Schedule key='schedule' />,
      <Pastors key='pastors' />,
      <Direction key='direction' />,
      <Announcements key='Announcements' />,
      <Sermons key='sermons' />
    ])
  }
}

export default MainScene