import React, { Component } from 'react'

import { Button } from 'semantic-ui-react'

import GoogleMapReact from 'google-map-react';

const NAVER_MAP_LINK = 'http://map.naver.com/?searchCoord=ef155983d0be2ed9b2d4c2c0000b402a745abb198b06fb5f84d14913579b0ab6&query=6rK96riw64%2BEIOyEseuCqOyLnCDrtoTri7nqtawg7Jq07KSR66GcIDE3OOuyiOq4uCAx&menu=location&tab=1&lng=ea5e049456601e054f4a8cd4c468da5b&mapMode=0&mpx=09140550%3A37.5676848%2C126.9772482%3AZ11%3A0.0344696%2C0.0162879&lat=0f69192433614954fa70d1df4c874818&dlevel=13&enc=b64';
const KAKAO_MAP_LINK = 'http://map.daum.net/?urlX=518380&urlY=1080986&q=%EA%B2%BD%EA%B8%B0+%EC%84%B1%EB%82%A8%EC%8B%9C+%EB%B6%84%EB%8B%B9%EA%B5%AC+%EC%9A%B4%EC%A4%91%EB%A1%9C178%EB%B2%88%EA%B8%B8+1';

class Direction extends Component {
  static defaultProps = {
    center: {
      lat: 37.3908123,
      lng: 127.0830182
    },
    zoom: 15
  };

  handleMapLoaded = (map, maps) => {
    const _ = new maps.Marker({
      position: this.props.center,
      map,
      title: '진리등대교회'
    });
  }

  openMap = (link) => {
    window.open(link, "_blank")
  }

  render() {
    return (
      <section id='direction' className='flex-vcenter'>
        <div className='contents'><div>
          <h4>오시는 길</h4>
          <h5><span>주소:</span> 경기도 성남시 분당구 운중로 178번길 1 스타식스로데오B/D 4층</h5>
          <h5><span>버스 이용시:</span> 9003, 9004, 1550-3, 330, 340, 350 뫼루니육교 하차</h5>
          <div className='map-links-container'>
            <Button onClick={() => this.openMap(NAVER_MAP_LINK)}>네이버 지도</Button>
            <Button onClick={() => this.openMap(KAKAO_MAP_LINK)}>카카오 지도</Button>
          </div>
        </div></div>
        <div className='maps-container' style={{ height: '100%', width: '50%' }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyB8Nm1y-uVqHlAc5sesX29mac8ab02A4MA' }}
            defaultCenter={this.props.center}
            defaultZoom={this.props.zoom}
            yesIWantToUseGoogleMapApiInternals={true}
            onGoogleApiLoaded={({map, maps}) => this.handleMapLoaded(map, maps)}>
          </GoogleMapReact>
        </div>
      </section>
    )
  }
}

export default Direction