import React, { Component } from 'react'

import SermonsHeader from 'components/SermonsHeader';
import SermonsList from 'components/SermonsList';
import SermonsPagination from 'components/SermonsPagination';

import OptionAPI from 'services/api/Option';
import SermonAPI from 'services/api/Sermon';

const defaultState = {
  currentPage: 1,
  seriesOptions: [],
  lectureOptions: [],
  query: {
    search: '',
    series: '',
    lecture: '',
  },
  sermons: [],
  type: '전체'
}

class Sermons extends Component {
  state = defaultState

  handlers = {
    clearQuery: () => {
      let query = Object.assign({}, defaultState.query);
      this.setState({currentPage: 1, query: query});
    },
    updateType: (type) => {
      this.setState({currentPage: 1, type: type});
    },
    updateSearch: (search) => {
      let query = Object.assign({}, defaultState.query);
      query['search'] = search;
      this.setState({currentPage: 1, query: query});
    },
    updateSeries: (evt, data) => {
      let query = Object.assign({}, defaultState.query);
      query['series'] = data.value;
      this.setState({currentPage: 1, query: query});
    },
    updateLecture: (evt, data) => {
      let query = Object.assign({}, defaultState.query);
      query['lecture'] = data.value;
      this.setState({currentPage: 1, query: query});
    },
    updatePage: (evt, data) => {
      this.setState({currentPage: data.activePage});
    }
  }

  componentDidMount = async() => {
    const sermons = await SermonAPI.get();
    const options = await OptionAPI.get();
    this.setState({
      sermons: this.indexSermons(sermons.Items),
      seriesOptions: this.createOptions(options.series.Items),
      lectureOptions: this.createOptions(options.lecture.Items)
    });
  }

  createOptions = (list) => {
    const sortedItems = list.sort((a, b) => (parseInt(a.order.N) > parseInt(b.order.N)) ? 1 : -1);

    const options = sortedItems.map((option) => {
      return {key: option.content.S, text: option.content.S, value: option.content.S}
    });
    options.unshift({key: '', value: '', text: ''});
    return options;
  }

  indexSermons = (list) => {
    const length = list.length;
    const sermons = list.map((sermon, index) => {
      sermon['order'] = length - index;
      return sermon;
    });
    return sermons;
  }

  getFilteredSermons = () => {
    const sortedSermons = this.state.sermons.sort((a, b) => (a.date.S < b.date.S) ? 1 : -1)

    return sortedSermons.filter((sermon) => {
      if (this.state.type !== '전체' && sermon.sermon_type && this.state.type !== sermon.sermon_type.S) {
        return false;
      } else if (this.state.query.series !== '' && (sermon.series === undefined || this.state.query.series !== sermon.series.S)) {
        return false;
      } else if (this.state.query.lecture !== '' && (sermon.lecture === undefined || this.state.query.lecture !== sermon.lecture.S)) {
        return false;
      } else if (sermon.title.S.indexOf(this.state.query.search) < 0) {
        return false;
      }
      return true;
    });
  }

  getMaxPages = (numSermons) => {
    return Math.ceil(numSermons / 15);
  }

  render() {
    const allFilteredSermons = this.getFilteredSermons();
    const numPages = this.getMaxPages(allFilteredSermons.length);

    const startingIndex = (this.state.currentPage - 1) * 15;
    const filteredSermons = allFilteredSermons.slice(startingIndex, startingIndex + 15);


    return (
      <section id='sermons'>
        <div className='contents'>
          <SermonsHeader type={this.state.type} 
                         options={{series: this.state.seriesOptions, lecture: this.state.lectureOptions}}
                         handlers={this.handlers} />
          <SermonsList query={this.state.query} sermons={filteredSermons} handlers={this.handlers} />
          {numPages > 1 &&
            <SermonsPagination config={{max: numPages, default: this.state.currentPage}} 
                               handlers={this.handlers} />
          }
        </div>
      </section>
    )
  }
}

export default Sermons