import React, { Component } from 'react'

import AnnouncementAPI from 'services/api/Announcement';

class Announcements extends Component {
  state = {
    announcements: [],
  }

  componentDidMount = async() => {
    const data = await AnnouncementAPI.get();
    this.setState({announcements: data.Items});
  }

  render() {
    const filteredAnnouncements = this.state.announcements.filter((announcement) => {
      return announcement.active && announcement.active.BOOL;
    });

    const listAnnouncements = filteredAnnouncements.map((announcement, index) => {
      return (
        <li key={index}>
          <div className='first-row clearfix'>
            <h5 className='title'>{announcement.title !== undefined && announcement.title.S}</h5>
            {announcement.date !== undefined &&
              <h6 className='date'>{announcement.date.S}</h6>
            }
          </div>
          <h6 className='content' dangerouslySetInnerHTML={{__html: announcement.content !== undefined && announcement.content.S}} />
        </li>
      );
    });

    return (
      <section id='announcements' className='flex-vcenter'>
        <div className='contents flex-right'><div className='wrapper'>
          <h4>교회소식</h4>
          <ul>{listAnnouncements}</ul>
        </div></div>
      </section>
    )
  }
}

export default Announcements