import React from 'react';
import ReactDOM from 'react-dom';

import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';

import 'semantic-ui-css/semantic.min.css';
import './styles/app.css';

import Main from 'pages/Main';

import * as serviceWorker from './serviceWorker';

Amplify.configure(awsconfig);

const App = () => (
  <Main />
)

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
