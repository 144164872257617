import React, { Component } from 'react'

import { Pagination } from 'semantic-ui-react';

class SermonsList extends Component {
  render() {
    const config = this.props.config;

    return (
      <div className='pagination-container flex-center'>
        <Pagination activePage={config.default} 
                    totalPages={config.max} 
                    onPageChange={this.props.handlers.updatePage} />
      </div>
    )
  }
}

export default SermonsList